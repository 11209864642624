import AppWidgetForm from "../../component/models/AppWidgetForm";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { DialogActions, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import frame1 from "../../../../Assets/images/Frame1.png"
import frame2 from "../../../../Assets/images/Frame 2.png"
import frame3 from "../../../../Assets/images/Frame 3.png"
import frame4 from "../../../../Assets/images/Frame 4.png"
import frame5 from "../../../../Assets/images/Frame5.png"
import frame6 from "../../../../Assets/images/Frame6.png"
import "./Channel.css"
const Channel = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const [listOfCards, setListOfCards] = useState([
    {
      name: "Main",
      img:frame1,
    },
    {
      name: "Main +  Footer",
      img: frame2,
    },
    {
      name: "Main + Laft  Bar",
      img: frame3,
    },
    {
      name: "Main + Right Bar",
      img: frame4,
    },
    {
      name: "Split Vertically",
      img: frame5,
    },
    {
      name: "Split Horizantally",
      img: frame6,
    },
  ]);
  return (
    <>
      <div className="wrap" style={{ fontFamily: "Poppins, sans-serif" }}>
        <AppWidgetForm
          isOpen={true}
          onClose={onClose}
          width="40vw" 
          height="50vh"
        >
          <div className="channelmainname"
           
          >
            <div>Channel </div>

            <span
             
              onClick={onClose}
            >
              {" "}
              <CloseIcon />
            </span>
          </div>

          <div
            style={{
              width: "17dvw",
              display: "flex",
              flexDirection: "column",

              padding: 20,
              gap: 10,
            }}
          >
            <span
              style={{
                color: "rgba(0,0,0,0.5)",
              }}
            >
              Channel Name
            </span>
            <input
              id="outlined-basic"
              label="Your Name Here"
              placeholder="Channel Name"
              variant="outlined"
              fullWidth
              style={{
                height: "35px",
                borderRadius: "8px",
                border: "1px solid rgba(0,0,0,0.3)",
                paddingLeft: "17px",
              }}
            />
          </div>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              marginTop: 1,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "18.5dvw",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "var(--primary-color)",
                    width: "17dvw",
                    height: "4vh",
                    padding: 20,
                    borderRadius: "8px",
                  }}
                  onClick={handleOpenModal}
                >
                  Save
                </Button>
              </div>
            </div>
          </DialogActions>
        </AppWidgetForm>
      </div>
      {/* <AppWidgetForm
        isOpen={isModalOpen} // Controlled by state
        onClose={handleCloseModal} // Function to close the modal
        style={{ height: "60vh", width: "40vw" }}
       
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding: "15px",
            marginTop: "8px",
            width: "32vw",
          }}
        >
          <span>Choose Layout</span>

          <span
            style={{
              border: "none",
              background: "transparent",
              color: "black",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          >
            {" "}
            <CloseIcon />
          </span>
        </div>
        <div className="content" style={{width:"35vw",height:"42vh", display:"flex",justifyContent:"center"}}>
        <div className="cards"  style={{height:"113px",display:"flex",flexWrap:"wrap", margin:"0px", gap:"20px",cursor:"pointer"}}>
        
          {listOfCards.map((key,index) => (
            <>
           
                <div className="maincard"style={{width:"146px",height:"113px", margin:"16px"}}>
                <div className="img" style={{width:"146px",height:"91px",border:"0.5px solid #EEEDED",borderRadius:"8px",display:"flex",alignItems:"center",justifyContent:"center"}}> <img src={key.img} alt="" /></div>
                <div className="name" style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"12px"}}>{key.name}</div>
                </div>
            </>
          ))}
        </div>
        </div>
        <div style={{ display: "flex" }}>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                  }}
                 
                >
                  Select
                </Button>
              </div>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "white",
                    color:"var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                    border:"1px solid var(--primary-color)"
                  }}
                  onClick={handleCloseModal}
                >
              Close
                </Button>
              </div>
            </div>
           
      </AppWidgetForm> */}
      <AppWidgetForm
        isOpen={isModalOpen} // Controlled by state
        onClose={handleCloseModal} // Function to close the modal
        style={{ height: "60vh", width: "40vw" }}
       
      >      
      <div
       className="channelmainnamea"
      >
        <span>Add Playlist to Channel Section</span>

        <span
          style={{
            border: "none",
            background: "transparent",
            color: "black",
            cursor: "pointer",
          }}
          onClick={handleCloseModal}
        >
          {" "}
          <CloseIcon />
        </span>
      </div>
      <div
                      className="mainwrap"
                     
                    >
                      <div style={{ margin: "20px " }}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px",marginRight:"13px" }}>Oppo Webview</span>
  </div>
</label>
                      </div>
                      
                      <div style={{ margin: "20px " }}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px" }}>Final Video</span>
  </div>
</label>
                      </div>
                      <div style={{margin: "20px "}}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px", marginRight:"47px"}}>Web Page</span>
  </div>
</label>
                      </div>
                      <div style={{ margin: "20px " }}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px" }}>Khttk</span>
  </div>
</label>
                      </div>
                      <div style={{margin: "20px "}}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px" }}>Right Bar Playlist</span>
  </div>
</label>
                      </div>
                      <div style={{margin: "20px " }}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px" }}>Main Playlist</span>
  </div>
</label>
                      </div>
                      <div style={{margin: "20px "}}>
                      <label>
  <div className="wrapcheck" style={{ display: "flex", alignItems: "center" }}>
    <div className="custom-checkbox">
      <input type="checkbox" className="checkbox-input" id="blogs-checkbox" />
      <span className="checkmark"></span> {/* Custom checkmark */}
    </div>
    <span style={{ marginLeft: "20px" }}>Kotlin Testing</span>
  </div>
</label>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                  }}
                 
                >
                  Close
                </Button>
              </div>
              <div
                className="btnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 margin:"16px"
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    background: "white",
                    color:"var(--primary-color)",
                    width: "151px",
                    height: "40px",
                    padding: 20,
                    borderRadius: "8px",
                    border:"1px solid var(--primary-color)"
                  }}
                  onClick={handleCloseModal}
                >
              Save
                </Button>
              </div>
            </div>
    </AppWidgetForm>
    </>
  );
};

export default Channel;
